import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCEXp0YL1zg_q5-oaDwPf0sZKLE_4YCpzg',
  authDomain: 'codex-martin-lutero.firebaseapp.com',
  projectId: 'codex-martin-lutero',
  storageBucket: 'codex-martin-lutero.appspot.com',
  messagingSenderId: '531624181981',
  appId: '1:531624181981:web:fa425b94f00dfefdad5b34',
  measurementId: 'G-Q5V02QN958',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
